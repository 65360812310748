// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import Login from '../components/Login.vue';
import Register from '../components/Register.vue';
import Dashboard from '../components/Dashboard.vue';
import Statistics from '../components/Statistics.vue';
import ChangePassword from '../components/ChangePassword.vue';
import OperationRecords from '../components/OperationRecords.vue'; // 引入操作记录组件

const routes = [
  { path: '/', name: 'Login', component: Login },
  { path: '/register', name: 'Register', component: Register },
  { path: '/dashboard', name: 'Dashboard', component: Dashboard },
  { path: '/statistics', name: 'Statistics', component: Statistics },
  { path: '/change-password', name: 'ChangePassword', component: ChangePassword },
  { path: '/operation-records', name: 'OperationRecords', component: OperationRecords } // 添加新的路由
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  const publicPages = ['Login', 'Register'];
  const authRequired = !publicPages.includes(to.name);
  const loggedIn = localStorage.getItem('member_id');

  if (authRequired && !loggedIn) {
    return next('/');
  }

  next();
});

export default router;
