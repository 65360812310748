// src/config.js
const API_BASE_URL ='http://154.91.82.42:8200'   
export default API_BASE_URL;


// 'http://154.91.82.42:8200'     //ruiwen
// 'http://206.238.43.123:8200'  //weiwei02 wenxi
// 'http://154.82.84.124:8200'  //weiwei03
// 'http://154.82.84.81:8200'  //weiwei04

// 'http://45.204.212.60:8200'  //7天测试开发