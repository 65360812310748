<template>
  <el-form :model="form" label-width="100px" class="edit-form">
    <el-form-item label="原卡">
      <el-input v-model="form.original_card" placeholder="请输入原卡"></el-input>
    </el-form-item>
    <el-form-item label="变卡">
      <el-input v-model="form.var_card" placeholder="请输入变卡"></el-input>
    </el-form-item>
    <el-form-item label="起扣">
      <el-input v-model="form.deduct_start" placeholder="请输入起扣"></el-input>
    </el-form-item>
    <el-form-item label="系数">
      <el-input v-model="form.coefficient" placeholder="请输入系数"></el-input>
    </el-form-item>
    <el-form-item label="归属" class="form-item-inline">
      <el-cascader
        v-model="selectedType"
        :options="options"
        :props="{ value: 'value', label: 'label', children: 'children', emitPath: true }"
        placeholder="请选择归属"
        filterable
        @update:modelValue="handleChange"
      />
    </el-form-item>
  </el-form>
</template>



<script>
export default {
  props: {
    form: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      options: [  //ruiwen
        {
          label: '组1->http://csdx.znpzn1d.shop',
          value: 'http://csdx.znpzn1d.shop',
          children: [
            { label: 'http://k29jt25bi.shop', value: 'http://k29jt25bi.shop' },
            { label: 'http://sssss.853005b9.shop', value: 'http://sssss.853005b9.shop' },
            { label: 'http://m72gl8jy.shop', value: 'http://m72gl8jy.shop' },
            { label: 'http://w20ps.k3cdn.wo.tc', value: 'http://w20ps.k3cdn.wo.tc' },
            { label: 'http://work.6qb6f2fx.skin', value: 'http://work.6qb6f2fx.skin' },
            { label: 'http://work.yuwxgi6e.skin', value: 'http://work.yuwxgi6e.skin' },
            { label: 'https://fze11ffa.us', value: 'https://fze11ffa.us' },
            { label: 'http://f36jh.k3cdn.wo.tc', value: 'http://f36jh.k3cdn.wo.tc' },
            { label: 'http://m91tj430dy.shop', value: 'http://m91tj430dy.shop' },
            { label: 'http://work.xduqai31.skin', value: 'http://work.xduqai31.skin' },
            { label: 'http://sssss5.853005b9.shop', value: 'http://sssss5.853005b9.shop' },
            { label: 'http://workbench.fd78zhen.skin', value: 'http://workbench.fd78zhen.skin' },
            { label: 'http://chat.nxrxnn1a.skin', value: 'http://chat.nxrxnn1a.skin' },
            { label: 'http://dd5883.afegsdg.cn', value: 'http://dd5883.afegsdg.cn' },
            { label: 'http://cxdd333.hsb747.shop', value: 'http://cxdd333.hsb747.shop' },
            { label: 'http://cscx.65zm6k.com', value: 'http://cscx.65zm6k.com' },
            { label: 'http://arcx168.7ab116.com', value: 'http://arcx168.7ab116.com' },
            { label: 'http://ab6666.9xrinkat.cn', value: 'http://ab6666.9xrinkat.cn' },
            { label: 'http://query.rsm0i2.cn', value: 'http://query.rsm0i2.cn' },
            { label: 'http://cscx.xpz2ywzp.cn', value: 'http://cscx.xpz2ywzp.cn' },
            { label: 'http://sadad.ndsf13.cn', value: 'http://sadad.ndsf13.cn' },
            { label: 'http://s1chax.s1fsd.sdftmychat.cn.cdn.cloudflare.net', value: 'http://s1chax.s1fsd.sdftmychat.cn.cdn.cloudflare.net' },
            { label: 'http://csy.query.2lz02g8a.vip', value: 'http://csy.query.2lz02g8a.vip' }, 
            { label: 'http://jjfhsf.1m9b18.shop', value: 'http://jjfhsf.1m9b18.shop' },
            { label: 'http://dfm2.chax.3p2mu08o.vip', value: 'http://dfm2.chax.3p2mu08o.vip' },
            { label: 'http://18.162.118.192', value: 'http://18.162.118.192' },
            { label: 'http://adfbcx.t9yt3he0.vip', value: 'http://adfbcx.t9yt3he0.vip' },
            { label: 'http://xyzw.xjdsj3jr.cn', value: 'http://xyzw.xjdsj3jr.cn' },
            { label: 'http://s2chax.s2fsd.sdftmychat.cn.cdn.cloudflare.net', value: 'http://s2chax.s2fsd.sdftmychat.cn.cdn.cloudflare.net' },
          ],
        },
        {
          label: '组2->http://tffcx.uebizsjih.shop',
          value: 'http://tffcx.uebizsjih.shop',
          children: [
            { label: 'http://sssss.853005b9.shop', value: 'http://sssss.853005b9.shop' },
            { label: 'http://work.ue4vo16g.skin', value: 'http://work.ue4vo16g.skin' },
 { label: 'http://s2chax.s2fsd.sdftmychat.cn.cdn.cloudflare.net', value: 'http://s2chax.s2fsd.sdftmychat.cn.cdn.cloudflare.net' },
            { label: 'http://s1chax.s1fsd.sdftmychat.cn.cdn.cloudflare.net', value: 'http://s1chax.s1fsd.sdftmychat.cn.cdn.cloudflare.net' },
            { label: 'http://pxyun.supeses.shop', value: 'http://pxyun.supeses.shop' },
            { label: 'http://hfhcx668.alvx470gid.shop', value: 'http://hfhcx668.alvx470gid.shop' },
            { label: 'http://chax.query.league778.com', value: 'http://chax.query.league778.com' },
            { label: 'https://fze11ffa.us', value: 'https://fze11ffa.us' },
            { label: 'http://f36jh.k3cdn.wo.tc', value: 'http://f36jh.k3cdn.wo.tc' },
            { label: 'http://m91tj430dy.shop', value: 'http://m91tj430dy.shop' },
            { label: 'http://sssss5.853005b9.shop', value: 'http://sssss5.853005b9.shop' },
            { label: 'http://dd8888.yefeng.shop', value: 'http://dd8888.yefeng.shop' },
            { label: 'http://query.rsm0i2.cn', value: 'http://query.rsm0i2.cn' },
            { label: 'http://jjfhsf.1m9b18.shop', value: 'http://jjfhsf.1m9b18.shop' },
            { label: 'http://okfcdj.n8097h.shop', value: 'http://okfcdj.n8097h.shop' },
            { label: 'http://cscx.65zm6k.com', value: 'http://cscx.65zm6k.com' },
            { label: 'http://ay3333.hsb747.shop', value: 'http://ay3333.hsb747.shop' },
            { label: 'http://ab6666.9xrinkat.cn', value: 'http://ab6666.9xrinkat.cn' },
            { label: 'http://arcx168.7ab116.com', value: 'http://arcx168.7ab116.com' },
            { label: 'http://cscx.xpz2ywzp.cn', value: 'http://cscx.xpz2ywzp.cn' },
            { label: 'http://sadad.ndsf13.cn', value: 'http://sadad.ndsf13.cn' },
            { label: 'http://dd5883.afegsdg.cn', value: 'http://dd5883.afegsdg.cn' },
            { label: 'http://seak.fa88analysis.online', value: 'http://seak.fa88analysis.online' },
            { label: 'http://dfm2.chax.3p2mu08o.vip', value: 'http://dfm2.chax.3p2mu08o.vip' },
            { label: 'http://adfbcx.t9yt3he0.vip', value: 'http://adfbcx.t9yt3he0.vip' },
            { label: 'http://chat.nxrxnn1a.skin', value: 'http://chat.nxrxnn1a.skin' },
          ],
        },
        {
          label: '组3->http://arcxt.lq2wmny.group',
          value: 'http://arcxt.lq2wmny.group',
          children: [
            { label: 'http://work.6qb6f2fx.skin', value: 'http://work.6qb6f2fx.skin' },
          ],
        },
        {
          label: '组4->http://hfdcx.3unn185x.shop',
          value: 'http://hfdcx.3unn185x.shop',
          children: [
            { label: 'http://xzkfcx.sschax.vip', value: 'http://xzkfcx.sschax.vip' },
          ],
        },
        {
          label: '组5->http://xzyfcx22.mcx86072.top',
          value: 'http://xzyfcx22.mcx86072.top',
          children: [

          ],
        },
        {
          label: '组6->http://lthycx.gctxtqkh.shop',
          value: 'http://lthycx.gctxtqkh.shop',
          children: [
            { label: 'http://f36jh.k3cdn.wo.tc', value: 'http://f36jh.k3cdn.wo.tc' },
            { label: 'http://chat.xuwbzpe6.skin', value: 'http://chat.xuwbzpe6.skin' },
            { label: 'http://query.rsm0i2.cn', value: 'http://query.rsm0i2.cn' },
            { label: 'http://s3bcx.09wbpc3u.vip', value: 'http://s3bcx.09wbpc3u.vip' },
            { label: 'http://nxcx1.qxl3f7j3.cn', value: 'http://nxcx1.qxl3f7j3.cn' },
          ],
        },
        {
          label: '组7->http://znkgotld.vvllgk.group',
          value: 'http://znkgotld.vvllgk.group',
          children: [
            { label: 'http://workbench.fd78zhen.skin', value: 'http://workbench.fd78zhen.skin' },
          ],
        },
      ],
    };
  },
  watch: {
    form: {
      handler(val) {
        this.setDefaultType(val.query_website, val.type);
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    handleChange(value) {
      console.log("value:", value);
      if (value && value.length > 0) {
        this.form.query_website = value[0];
        this.form.type = value[1] ? value[1] : '';
      } else {
        this.form.query_website = '';
        this.form.type = '';
      }
      console.log("form.query_website:", this.form.query_website);
      console.log("form.type:", this.form.type);
    },
    setDefaultType(queryWebsite, type) {
      const path = this.findPath(this.options, queryWebsite, type);
      if (path) {
        this.selectedType = path;
      } else {
        this.selectedType = [];
      }
    },
    findPath(options, queryWebsite, type) {
      for (const option of options) {
        if (option.value === queryWebsite) {
          for (const child of option.children) {
            if (child.value === type) {
              return [option.value, child.value];
            }
          }
        }
      }
      return null;
    }
  }
};
</script>

<style scoped>
.edit-form {
  max-width: 400px;
  margin: 0 auto;
}

.el-form-item {
  margin-bottom: 20px;
}

.el-input {
  width: 100%;
}

.el-form-item__label {
  font-weight: bold;
  color: #303133;
}

.el-input__inner {
  border-radius: 4px;
}

.el-input__inner:focus {
  border-color: #409EFF;
  box-shadow: 0 0 8px rgba(64, 158, 255, 0.2);
}
</style>
