<template>
  <div class="change-password-container">
    <el-card class="change-password-card">
      <h2>修改密码</h2>
      <el-form :model="passwordForm" @submit.prevent="changePassword">
        <el-form-item label="当前密码" :label-width="formLabelWidth">
          <el-input v-model="passwordForm.currentPassword" type="password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="新密码" :label-width="formLabelWidth">
          <el-input v-model="passwordForm.newPassword" type="password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认新密码" :label-width="formLabelWidth">
          <el-input v-model="passwordForm.confirmNewPassword" type="password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="changePassword">提交</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import API_BASE_URL from '../config';
export default {
  data() {
    return {
      passwordForm: {
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: ''
      },
      formLabelWidth: '120px'
    };
  },
  methods: {
    async changePassword() {
      const member_id = localStorage.getItem('member_id');

      if (!member_id) {
        this.$message.error('会员ID缺失，请重新登录');
        return;
      }

      if (this.passwordForm.newPassword !== this.passwordForm.confirmNewPassword) {
        this.$message.error('新密码和确认新密码不一致');
        return;
      }

      try {
        const response = await fetch(`${API_BASE_URL}/change-password`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            ...this.passwordForm,
            member_id
          })
        });
        const result = await response.json();
        if (result.success) {
          this.$message.success('密码修改成功');
          this.$router.push('/dashboard');
        } else {
          this.$message.error(result.message);
        }
      } catch (error) {
        console.error('修改密码时出错:', error);
        this.$message.error('密码修改失败');
      }
    }
  }
};
</script>

<style scoped>
.change-password-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: transparent; /* 背景透明 */
  padding-top: 20px; /* 减少上方的填充来上移整个容器 */
}

.change-password-card {
  width: 400px;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.8); /* 半透明背景 */
  text-align: center;
}

h2 {
  margin-bottom: 20px;
  color: #333333;
}
</style>
