import { createStore } from 'vuex'

export default createStore({
  state: {
    // 定义你的状态
    count: 0
  },
  mutations: {
    // 定义你的变更函数
    increment(state) {
      state.count++
    }
  },
  actions: {
    // 定义你的动作
    increment({ commit }) {
      commit('increment')
    }
  },
  modules: {
    // 定义你的模块
  }
})
