<template>
  <div class="auth-background">
    <div class="auth-container">
      <div class="auth-card">
        <h2>火花欢迎您</h2>
        <el-form :model="loginForm" @submit.prevent="login">
          <el-form-item>
            <el-input
              v-model="loginForm.name"
              placeholder="用户名"
              clearable
              prefix-icon="el-icon-user"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-input
              type="password"
              v-model="loginForm.password"
              placeholder="密码"
              clearable
              prefix-icon="el-icon-lock"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="login"
              class="login-button"
              round
              size="large"
              :loading="isLoading"
            >登录</el-button>
          </el-form-item>
          <el-link @click="goToRegister" class="register-link"
            >没有账号？去注册</el-link
          >
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import API_BASE_URL from '../config';
export default {
  data() {
    return {
      loginForm: {
        name: '',
        password: ''
      },
      isLoading: false
    };
  },
  methods: {
    async login() {
      this.isLoading = true;
      try {
        const response = await fetch(`${API_BASE_URL}/login`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.loginForm)
        });
        const result = await response.json();
        if (result.success) {
          localStorage.setItem('member_id', result.member.id);
          localStorage.setItem('member_name', result.member.name);
          this.$router.push('/dashboard').then(() => {
            window.location.reload();
          });
        } else {
          this.$message.error('登录失败');
        }
      } catch (error) {
        console.error(error);
        this.$message.error('登录失败');
      } finally {
        this.isLoading = false;
      }
    },
    goToRegister() {
      this.$router.push('/register');
    }
  }
};
</script>

<style scoped>
.auth-background {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-image: url('https://pic.netbian.com/uploads/allimg/240712/235820-1720799900b518.jpg');
  background-size: cover;
  background-position: center;
}

.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.auth-card {
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.1);
  text-align: center;
  width: 400px;
  z-index: 1;
}

h2 {
  margin-bottom: 20px;
  color: #333333;
}

.login-button {
  width: 100%;
}

.register-link {
  display: block;
  margin-top: 10px;
}
</style>
