<template>
  <div class="operation-records-container">
    <el-card class="box-card">
      <div class="search-container">
        <el-input
          v-model="searchQuery"
          placeholder="搜索操作内容"
          class="search-input"
          clearable
          @clear="handleSearch"
        />
        <el-button @click="handleSearch" type="primary">搜索</el-button>
        <el-button @click="handleRefresh" type="success">刷新</el-button>
      </div>
      <el-table :data="operationRecords" class="table-container" style="width: 100%;" border stripe>
        <el-table-column prop="id" label="ID" width="60"></el-table-column>
        <el-table-column prop="OperationContent" label="操作内容"></el-table-column>
        <el-table-column prop="OperationTime" label="操作时间" width="180"></el-table-column>
        <el-table-column prop="OperationIP" label="操作IP" width="180"></el-table-column>
      </el-table>
      <div class="pagination-container">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totalRecords">
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import API_BASE_URL from '../config';

export default {
  name: 'OperationRecords',
  data() {
    return {
      operationRecords: [],
      searchQuery: '',
      currentPage: 1,
      pageSize: 10,
      totalRecords: 0
    };
  },
  methods: {
    async fetchOperationRecords() {
      const member_id = localStorage.getItem('member_id');
      const response = await fetch(`${API_BASE_URL}/operation-records?member_id=${member_id}&page=${this.currentPage}&limit=${this.pageSize}&searchQuery=${this.searchQuery}`);
      const result = await response.json();
      if (result.success) {
        this.operationRecords = result.data;
        this.totalRecords = result.total;
      } else {
        this.$message.error(result.message);
      }
    },
    handleSearch() {
      this.currentPage = 1;
      this.fetchOperationRecords();
    },
    handleRefresh() {
      this.searchQuery = '';
      this.fetchOperationRecords();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.fetchOperationRecords();
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.fetchOperationRecords();
    }
  },
  created() {
    this.fetchOperationRecords();
  }
};
</script>

<style scoped>
.operation-records-container {
  padding: 20px;
  margin-top: 5px; /* 与导航栏的距离 */
}

.search-container {
  display: flex;
  margin-bottom: 10px;
}

.search-input {
  margin-right: 10px;
  width: 300px;
}

.table-container {
  margin-top: 10px;
}

.pagination-container {
  margin-top: 10px;
}

.box-card {
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
</style>
