<template>
  <el-container>
    <!-- Main Content -->
    <el-main>
      <div class="content-spacing">
        <el-card class="box-card">
          <el-form @submit.prevent="submitForm" class="form-container">
            <el-row :gutter="20">
              <!-- 左边输入框 -->
              <el-col :span="12">
                <el-form-item>
                  <el-input type="textarea" v-model="form.original_cards" placeholder="每行一条（输入原卡）" rows="7"></el-input>
                </el-form-item>
              </el-col>

              <!-- 右边输入框 -->
              <el-col :span="12">
                <el-form-item>
                  <el-input type="textarea" v-model="notesContent" placeholder="随手笔记 (每隔30秒自动保存)" rows="7"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <div class="form-row">
              <el-form-item label="起扣" class="form-item-inline">
                <div class="deduct-start-container" style="display: flex;">
                  <el-input-number v-for="(day, index) in form.deduct_start" :key="index"
                    v-model.number="form.deduct_start[index]" :placeholder="'第 ' + (index + 1) + ' 天'" :min="0"
                    :step="1" style="margin-right: 10px;" />
                </div>
              </el-form-item>
              <el-form-item label="系数" class="form-item-inline">
                <el-input-number v-model="form.coefficient" :step="0.1" placeholder="系数"></el-input-number>
              </el-form-item>

              <el-form-item label="归属" class="form-item-inline">
                <el-cascader v-model="form.type" :options="options" placeholder="请选择归属" @change="handleSelectionChangeS"
                  filterable />
              </el-form-item>


              <el-form-item label="前缀" class="form-item-inline">
                <el-input v-model="form.prefix" placeholder="默认跟随"></el-input>
              </el-form-item>
              <el-form-item class="form-item-inline submit-button">
                <el-button type="primary" @click="submitForm">提交</el-button>
              </el-form-item>
            </div>
          </el-form>
        </el-card>

        <el-card class="box-card">
          <div class="table-header">
            <el-input v-model="searchQuery" placeholder="搜索" class="search-input" clearable @clear="handleSearch" />
            <el-button @click="handleSearch" type="primary">搜索</el-button>
          </div>
          <el-table :data="dataList" class="table-container" style="width: 100%;" border stripe
            @selection-change="handleSelectionChange" table-layout="fixed">
            <el-table-column type="selection" width="40"></el-table-column>
            <el-table-column type="index" label="序号" width="60"></el-table-column>
            <el-table-column prop="var_card" label="变卡" width="260"></el-table-column>
            <el-table-column prop="original_card" label="原卡" width="260"></el-table-column>
            <el-table-column prop="deduct_start" label="起扣" width="200"></el-table-column>
            <el-table-column prop="coefficient" label="系数" width="60"></el-table-column>
            <el-table-column prop="type" label="归属" width="250"></el-table-column>
            <el-table-column prop="query_website" label="查询网站" width="250"></el-table-column>
            <el-table-column prop="created_at" label="创建时间" width="180"></el-table-column>
            <el-table-column label="操作" width="200" fixed="right">
              <template #default="scope">
                <el-button @click="copyCard(scope.row.var_card)" type="primary" size="small">复制</el-button>
                <el-popconfirm title="确定要删除这条记录吗？" confirmButtonText="确定" cancelButtonText="取消"
                  @confirm="deleteCard(scope.row.id)">
                  <template #reference>
                    <el-button type="danger" size="small">删除</el-button>
                  </template>
                </el-popconfirm>
                <el-button @click="editCard(scope.row)" type="success" size="small">编辑</el-button>
              </template>
            </el-table-column>
          </el-table>


          <div class="multi-buttons">
            <el-popconfirm title="确定要删除选中的记录吗？" confirmButtonText="确定" cancelButtonText="取消"
              @confirm="deleteSelectedCards">
              <template #reference>
                <el-button type="danger">删除选中</el-button>
              </template>
            </el-popconfirm>
            <el-button type="primary" @click="copySelectedCards">复制选中</el-button>
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 100, 200, 999]" :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
        </el-card>
      </div>
    </el-main>
  </el-container>
</template>

<script>
import { h } from 'vue';
import { ElMessageBox, ElNotification, ElMessage } from 'element-plus';
import EditForm from './EditForm.vue';
import API_BASE_URL from '../config';

export default {
  components: {
    EditForm
  },
  data() {
    return {
      notesContent: '', // 当前随手笔记的内容
      autoSaveInterval: null, // 保存自动保存的定时器
      form: {
        original_cards: '',
        deduct_start: [10, 10, 10, 10, 10, 10, 10], // 确保数组只有7个元素
        coefficient: 0.8,
        type: '',
        prefix: '',
        ak: '',
      },
      options: [  //ruiwen
        {
          label: '组1->http://csdx.znpzn1d.shop',
          value: 'http://csdx.znpzn1d.shop',
          children: [
            { label: 'http://k29jt25bi.shop', value: 'http://k29jt25bi.shop' },
            { label: 'http://sssss.853005b9.shop', value: 'http://sssss.853005b9.shop' },
            { label: 'http://m72gl8jy.shop', value: 'http://m72gl8jy.shop' },
            { label: 'http://w20ps.k3cdn.wo.tc', value: 'http://w20ps.k3cdn.wo.tc' },
            { label: 'http://work.6qb6f2fx.skin', value: 'http://work.6qb6f2fx.skin' },
            { label: 'http://work.yuwxgi6e.skin', value: 'http://work.yuwxgi6e.skin' },
            { label: 'https://fze11ffa.us', value: 'https://fze11ffa.us' },
            { label: 'http://f36jh.k3cdn.wo.tc', value: 'http://f36jh.k3cdn.wo.tc' },
            { label: 'http://m91tj430dy.shop', value: 'http://m91tj430dy.shop' },
            { label: 'http://work.xduqai31.skin', value: 'http://work.xduqai31.skin' },
            { label: 'http://sssss5.853005b9.shop', value: 'http://sssss5.853005b9.shop' },
            { label: 'http://workbench.fd78zhen.skin', value: 'http://workbench.fd78zhen.skin' },
            { label: 'http://chat.nxrxnn1a.skin', value: 'http://chat.nxrxnn1a.skin' },
            { label: 'http://dd5883.afegsdg.cn', value: 'http://dd5883.afegsdg.cn' },
            { label: 'http://cxdd333.hsb747.shop', value: 'http://cxdd333.hsb747.shop' },
            { label: 'http://cscx.65zm6k.com', value: 'http://cscx.65zm6k.com' },
            { label: 'http://arcx168.7ab116.com', value: 'http://arcx168.7ab116.com' },
            { label: 'http://ab6666.9xrinkat.cn', value: 'http://ab6666.9xrinkat.cn' },
            { label: 'http://query.rsm0i2.cn', value: 'http://query.rsm0i2.cn' },
            { label: 'http://cscx.xpz2ywzp.cn', value: 'http://cscx.xpz2ywzp.cn' },
            { label: 'http://sadad.ndsf13.cn', value: 'http://sadad.ndsf13.cn' },
            { label: 'http://s1chax.s1fsd.sdftmychat.cn.cdn.cloudflare.net', value: 'http://s1chax.s1fsd.sdftmychat.cn.cdn.cloudflare.net' },
            { label: 'http://csy.query.2lz02g8a.vip', value: 'http://csy.query.2lz02g8a.vip' }, 
            { label: 'http://jjfhsf.1m9b18.shop', value: 'http://jjfhsf.1m9b18.shop' },
            { label: 'http://dfm2.chax.3p2mu08o.vip', value: 'http://dfm2.chax.3p2mu08o.vip' },
            { label: 'http://18.162.118.192', value: 'http://18.162.118.192' },
            { label: 'http://adfbcx.t9yt3he0.vip', value: 'http://adfbcx.t9yt3he0.vip' },
            { label: 'http://xyzw.xjdsj3jr.cn', value: 'http://xyzw.xjdsj3jr.cn' },
            { label: 'http://s2chax.s2fsd.sdftmychat.cn.cdn.cloudflare.net', value: 'http://s2chax.s2fsd.sdftmychat.cn.cdn.cloudflare.net' },
          ],
        },
        {
          label: '组2->http://tffcx.uebizsjih.shop',
          value: 'http://tffcx.uebizsjih.shop',
          children: [
            { label: 'http://sssss.853005b9.shop', value: 'http://sssss.853005b9.shop' },
            { label: 'http://work.ue4vo16g.skin', value: 'http://work.ue4vo16g.skin' },
 { label: 'http://s2chax.s2fsd.sdftmychat.cn.cdn.cloudflare.net', value: 'http://s2chax.s2fsd.sdftmychat.cn.cdn.cloudflare.net' },
            { label: 'http://s1chax.s1fsd.sdftmychat.cn.cdn.cloudflare.net', value: 'http://s1chax.s1fsd.sdftmychat.cn.cdn.cloudflare.net' },
            { label: 'http://pxyun.supeses.shop', value: 'http://pxyun.supeses.shop' },
            { label: 'http://hfhcx668.alvx470gid.shop', value: 'http://hfhcx668.alvx470gid.shop' },
            { label: 'http://chax.query.league778.com', value: 'http://chax.query.league778.com' },
            { label: 'https://fze11ffa.us', value: 'https://fze11ffa.us' },
            { label: 'http://f36jh.k3cdn.wo.tc', value: 'http://f36jh.k3cdn.wo.tc' },
            { label: 'http://m91tj430dy.shop', value: 'http://m91tj430dy.shop' },
            { label: 'http://sssss5.853005b9.shop', value: 'http://sssss5.853005b9.shop' },
            { label: 'http://dd8888.yefeng.shop', value: 'http://dd8888.yefeng.shop' },
            { label: 'http://query.rsm0i2.cn', value: 'http://query.rsm0i2.cn' },
            { label: 'http://jjfhsf.1m9b18.shop', value: 'http://jjfhsf.1m9b18.shop' },
            { label: 'http://okfcdj.n8097h.shop', value: 'http://okfcdj.n8097h.shop' },
            { label: 'http://cscx.65zm6k.com', value: 'http://cscx.65zm6k.com' },
            { label: 'http://ay3333.hsb747.shop', value: 'http://ay3333.hsb747.shop' },
            { label: 'http://ab6666.9xrinkat.cn', value: 'http://ab6666.9xrinkat.cn' },
            { label: 'http://arcx168.7ab116.com', value: 'http://arcx168.7ab116.com' },
            { label: 'http://cscx.xpz2ywzp.cn', value: 'http://cscx.xpz2ywzp.cn' },
            { label: 'http://sadad.ndsf13.cn', value: 'http://sadad.ndsf13.cn' },
            { label: 'http://dd5883.afegsdg.cn', value: 'http://dd5883.afegsdg.cn' },
            { label: 'http://seak.fa88analysis.online', value: 'http://seak.fa88analysis.online' },
            { label: 'http://dfm2.chax.3p2mu08o.vip', value: 'http://dfm2.chax.3p2mu08o.vip' },
            { label: 'http://adfbcx.t9yt3he0.vip', value: 'http://adfbcx.t9yt3he0.vip' },
            { label: 'http://chat.nxrxnn1a.skin', value: 'http://chat.nxrxnn1a.skin' },
          ],
        },
        {
          label: '组3->http://arcxt.lq2wmny.group',
          value: 'http://arcxt.lq2wmny.group',
          children: [
            { label: 'http://work.6qb6f2fx.skin', value: 'http://work.6qb6f2fx.skin' },
          ],
        },
        {
          label: '组4->http://hfdcx.3unn185x.shop',
          value: 'http://hfdcx.3unn185x.shop',
          children: [
            { label: 'http://xzkfcx.sschax.vip', value: 'http://xzkfcx.sschax.vip' },
          ],
        },
        {
          label: '组5->http://xzyfcx22.mcx86072.top',
          value: 'http://xzyfcx22.mcx86072.top',
          children: [

          ],
        },
        {
          label: '组6->http://lthycx.gctxtqkh.shop',
          value: 'http://lthycx.gctxtqkh.shop',
          children: [
            { label: 'http://f36jh.k3cdn.wo.tc', value: 'http://f36jh.k3cdn.wo.tc' },
            { label: 'http://chat.xuwbzpe6.skin', value: 'http://chat.xuwbzpe6.skin' },
            { label: 'http://query.rsm0i2.cn', value: 'http://query.rsm0i2.cn' },
            { label: 'http://s3bcx.09wbpc3u.vip', value: 'http://s3bcx.09wbpc3u.vip' },
            { label: 'http://nxcx1.qxl3f7j3.cn', value: 'http://nxcx1.qxl3f7j3.cn' },
          ],
        },
        {
          label: '组7->http://znkgotld.vvllgk.group',
          value: 'http://znkgotld.vvllgk.group',
          children: [
            { label: 'http://workbench.fd78zhen.skin', value: 'http://workbench.fd78zhen.skin' },
          ],
        },
      ],
      filterType: '',
      searchQuery: '',
      dataList: [],
      currentPage: 1,
      pageSize: 10,
      total: 0,
      selectedRows: [],
      activeIndex: '1',
      editForm: {
        id: '',
        original_card: '',
        var_card: '',
        deduct_start: '',
        coefficient: '',
        type: '',
        query_website: ''
      }
    };
  },
  watch: {
    'form.original_cards': function (newVal) {
      this.form.original_cards = this.cleanData(newVal);
    }
  },
  computed: {
    userName() {
      return localStorage.getItem('member_name');
    },
    userEmail() {
      return localStorage.getItem('member_email');
    }
  },
  methods: {
    cleanData(input) {
      const continuousCharsRegex = /\S{15,}/; // 匹配连续不被空格分割超过15个字符的正则表达式
      const linkRegex = /(http|https):\/\//; // 匹配链接的正则表达式

      return input
        .split('\n')
        .map(line => {
          // 如果行中包含链接，则直接返回空字符串
          if (linkRegex.test(line)) {
            return '';
          }
          // 否则，匹配连续不被空格分割超过15个字符的部分
          const match = line.match(continuousCharsRegex);
          return match ? match[0].trim() : ''; // 获取符合条件的部分并去除两侧空格
        })
        .filter(line => line !== '') // 过滤空行
        .join('\n');
    }

    ,
    handleSelectionChangeS(value) {
      if (value && value.length > 0) {
        this.form.query_website = value[0];
        this.form.type = value[1];
      }
      console.log(this.form.query_website)  //form.query_website  查询地址
      console.log(this.form.type)
      this.copyToClipboard(value);
    },
    copyToClipboard(text) {
      const textarea = document.createElement('textarea');
      textarea.value = text;
      document.body.appendChild(textarea);
      textarea.select();
      document.execCommand('copy');
      document.body.removeChild(textarea);
      this.$message({
        message: '复制成功',
        type: 'success'
      });
    },
    async fetchData() {
      const member_id = localStorage.getItem('member_id');
      const response = await fetch(`${API_BASE_URL}/data?member_id=${member_id}&page=${this.currentPage}&limit=${this.pageSize}&searchQuery=${this.searchQuery}&filterType=${this.filterType}`);
      const result = await response.json();
      this.dataList = result.data;
      this.total = result.total;
    },
    async submitForm() {
      const member_id = localStorage.getItem('member_id');
      const original_cards = this.form.original_cards.split('\n').map(card => card.trim()).filter(card => card);
      const results = [];
      const varCards = [];

      for (const original_card of original_cards) {
        let fixedPart = '';
        let variablePart = original_card.trim(); // 去掉前后空格

        const parts = original_card.split('-');
        if (parts.length === 2) {
          [fixedPart, variablePart] = parts;
        }

        if (variablePart.length < 10) {
          this.$message.error('原卡格式无效: ' + original_card);
          continue;
        }

        const chars = variablePart.split('');
        [chars[3], chars[12]] = [chars[12], chars[3]];
        [chars[8], chars[10]] = [chars[10], chars[8]];
        const var_card = fixedPart ? `${fixedPart}-${chars.join('')}` : chars.join('');
        const final_var_card = this.form.prefix ? `${this.form.prefix}-${chars.join('')}` : var_card;

        varCards.push(final_var_card);
        results.push({ original_card, var_card: final_var_card, deduct_start: this.form.deduct_start, coefficient: this.form.coefficient, type: this.form.type, query_website: this.form.query_website, member_id });
      }

      console.log('Submitting results:', results);

      try {
        const response = await fetch(`${API_BASE_URL}/data`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(results)
        });

        const result = await response.json();
        if (result.success) {
          this.$message.success('数据提交成功');
          this.form.original_cards = '';
          this.fetchData();
          // console.log('Form type:', this.form.type);  // 打印 this.form.type 以确保其有值
          // varCards.push(this.form.type);  // 插入 this.form.type 到 varCards 最后
          this.showResults(varCards);
          this.form.type = '';  //清空变量
          this.form.query_website = '';  //清空变量
        } else if (result.message === '检测到重复数据') {
          this.handleDuplicateData(result.data, results);
        } else {
          this.$message.error(result.message);
        }
      } catch (error) {
        this.$message.error('提交失败，请重试');
        console.error('Error submitting results:', error);
      }
    }
    ,
    async deleteCard(id) {
      const member_id = localStorage.getItem('member_id');
      const response = await fetch(`${API_BASE_URL}/data`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ids: [id], member_id })
      });
      await this.fetchData();
    },
    async deleteSelectedCards() {
      const member_id = localStorage.getItem('member_id');
      const ids = this.selectedRows.map(row => row.id);
      if (ids.length === 0) {
        this.$message.warning('请选择要删除的行');
        return;
      }
      const response = await fetch(`${API_BASE_URL}/data`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ids, member_id })
      });
      await this.fetchData();
    },
    async copySelectedCards() {
      const varCards = this.selectedRows.map(row => row.var_card).join('\n');
      if (varCards.length === 0) {
        this.$message.warning('请选择要复制的行');
        return;
      }
      if (navigator.clipboard) {
        try {
          await navigator.clipboard.writeText(varCards);
          this.$message.success('已复制到剪贴板');
        } catch (err) {
          console.error('复制失败:', err);
          this.$message.error('复制失败');
        }
      } else {
        this.fallbackCopyTextToClipboard(varCards);
      }
    },
    handleSelectionChange(val) {
      this.selectedRows = val;
    },
    async copyCard(var_card) {
      if (navigator.clipboard) {
        try {
          await navigator.clipboard.writeText(var_card);
          this.$message.success('已复制到剪贴板');
        } catch (err) {
          console.error('复制失败:', err);
          this.$message.error('复制失败');
        }
      } else {
        this.fallbackCopyTextToClipboard(var_card);
      }
    },
    fallbackCopyTextToClipboard(text) {
      const textArea = document.createElement("textarea");
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        const successful = document.execCommand('copy');
        const msg = successful ? '已复制到剪贴板' : '复制失败';
        this.$message.success(msg);
      } catch (err) {
        console.error('复制失败:', err);
        this.$message.error('复制失败');
      }

      document.body.removeChild(textArea);
    },
    async editCard(row) {
      this.editForm = { ...row };

      ElMessageBox({
        title: '编辑数据',
        message: h(EditForm, {
          form: this.editForm
        }),
        confirmButtonText: '保存',
        showCancelButton: true,
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            await this.submitEditForm();
            done();
          } else {
            done();
          }
        }
      }).catch(() => {
        // Handle cancel
      });
    },
    async submitEditForm() {
      const member_id = localStorage.getItem('member_id');
      const { type, ...rest } = this.editForm;
      console.log(this.editForm.query_website)
      const query_website = this.editForm.query_website // Assuming the first element is the query_website
      const correct_type = type  // Assuming the second element is the actual type

      console.log(correct_type);

      const response = await fetch(`${API_BASE_URL}/edit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ ...rest, type: correct_type, query_website, member_id })
      });

      const result = await response.json();
      if (result.success) {
        this.$message.success('数据更新成功');
        this.fetchData();
      } else {
        this.$message.error(result.message);
      }
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.fetchData();
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.fetchData();
    },
    handleSearch() {
      this.currentPage = 1;
      this.fetchData();
    },
    showResults(varCards) {
      console.log(this.form.query_website);
      const cardList = varCards.join('\n') + '\n' + "粉商查询地址:" + this.form.query_website;

      ElMessageBox({
        title: '生成的变卡',
        message: `<div class="message-box">
                    <textarea readonly style="width: 215%; height: 200px; border: none; resize: none; background: transparent; padding: 10px;">${cardList}</textarea>
                  </div>`,
        dangerouslyUseHTMLString: true,
        confirmButtonText: '复制并关闭',
        showCancelButton: false,
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            if (navigator.clipboard) {
              navigator.clipboard.writeText(cardList).then(() => {
                ElNotification({
                  title: '成功',
                  message: '内容已复制到剪贴板',
                  type: 'success'
                });
                done();
              }).catch(err => {
                ElNotification({
                  title: '失败',
                  message: '复制失败',
                  type: 'error'
                });
                done();
              });
            } else {
              this.fallbackCopyTextToClipboard(cardList);
              done();
            }
          } else {
            done();
          }
        }
      }).catch(() => {
        // Handle cancel
      });
    },
    handleDuplicateData(existingData, newData) {
      const existingList = existingData.map(item => `
        <li>${item.original_card} -> ${item.var_card} (起扣: ${item.deduct_start}, 系数: ${item.coefficient}, 归属: ${item.type}, 查询: ${item.query_website} 属于会员: ${item.member_name})</li>`).join('');
      const newList = newData.map(item => `
        <li>${item.original_card} -> ${item.var_card} (起扣: ${item.deduct_start}, 系数: ${item.coefficient}, 归属: ${item.type}, 查询: ${item.query_website} )</li>`).join('');

      ElMessageBox({
        title: '检测到重复数据，是否覆盖？',
        message: `
          <div>
            <h3>现有数据：</h3>
            <ul>${existingList}</ul>
            <h3>新数据：</h3>
            <ul>${newList}</ul>
          </div>
        `,
        dangerouslyUseHTMLString: true,
        confirmButtonText: '覆盖',
        cancelButtonText: '取消',
        showCancelButton: true,
        beforeClose: async (action, instance, done) => {
          if (action === 'confirm') {
            for (let item of newData) {
              item.overwrite = true;
            }
            const member_id = localStorage.getItem('member_id');
            const response = await fetch(`${API_BASE_URL}/data`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify(newData)
            });
            const result = await response.json();
            if (result.success) {
              this.$message.success('数据覆盖成功');
              this.fetchData();
            } else {
              this.$message.error(result.message);
            }
            done();
          } else {
            done();
          }
        }
      }).catch(() => {
        // Handle cancel
      });
    },
    logout() {
      localStorage.removeItem('member_id');
      localStorage.removeItem('member_name');
      localStorage.removeItem('member_email');
      this.$router.push('/');
    },
    // 读取最新的笔记内容
    async fetchLatestNote() {
      try {
        const response = await fetch(`${API_BASE_URL}/api/notes`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          }
        });

        if (!response.ok) {
          throw new Error('无法获取笔记');
        }

        const data = await response.json();
        if (data.length > 0) {
          this.notesContent = data[0].content; // 读取最新的一条笔记内容
        }
      } catch (error) {
        console.error('无法获取笔记', error);
      }
    },

    // 自动保存随手笔记内容
    async autoSaveNotes() {
      if (this.notesContent) {
        try {
          const response = await fetch(`${API_BASE_URL}/api/notes`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              content: this.notesContent
            })
          });

          if (!response.ok) {
            throw new Error('无法自动保存笔记');
          }

          console.log('笔记自动保存成功');
        } catch (error) {
          console.error('无法自动保存笔记', error);
        }
      }
    }
  },
  mounted() {
    // 页面加载时获取最新的笔记
    this.fetchLatestNote();

    // 每隔5秒自动保存一次随手笔记
    this.autoSaveInterval = setInterval(() => {
      this.autoSaveNotes();
    }, 30000); // 30秒
  },
  beforeDestroy() {
    // 页面销毁时清除定时器
    if (this.autoSaveInterval) {
      clearInterval(this.autoSaveInterval);
    }
  },
  created() {
    this.fetchData();
  }
};
</script>

<style scoped>
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #001529;
  /* 修改背景色 */
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  /* 添加阴影 */
}

.header-left {
  flex: 1;
}

.header-right {
  display: flex;
  align-items: center;
}

.header-right .username {
  margin-left: 10px;
  margin-right: 20px;
  color: white;
  font-weight: bold;
  text-shadow: 0 0 8px rgba(255, 255, 255, 0.8);
  /* 发光效果 */
}

.logout-button {
  color: white;
  /* 设置文字颜色为白色 */
}

.content-spacing {
  margin-top: 5px;
  /* 增加的间距 */
}

.form-container {
  margin-bottom: 5px;
}

.form-row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.form-item-inline {
  margin-right: 10px;
  flex: 1 1 20%;
}

.submit-button {
  flex: 1 1 0%;
  margin-left: 10px;
}

.table-container {
  margin-top: 10px;
  overflow-x: auto;
  width: 100%;
  table-layout: fixed;
}

.box-card {
  margin-bottom: 5px;
  border-radius: 8px;
  width: 100%;
}

.multi-buttons {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
  margin-top: 10px;
  align-items: center;
}

.message-box {
  width: 100%;
}

@media (max-width: 768px) {
  .header-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .header-right {
    margin-top: 10px;
  }

  .form-item-inline {
    flex: 1 1 100%;
  }

  .submit-button {
    margin: 20px 0;
    width: 100%;
  }

  .table-container {
    font-size: 14px;
  }
}

.table-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
</style>
