<template>
  <el-container>
    <el-header v-if="showNavbar" class="header">
      <div class="header-content">
        <div class="header-left">
          <el-menu
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
            background-color="#001529"
            text-color="#fff"
            active-text-color="#ffd04b"
            :collapse-transition="false"
          >
            <el-menu-item index="1" @click="navigateTo('/dashboard')">
              <i class="el-icon-s-home"></i>
              主页
            </el-menu-item>
            <el-menu-item index="2" @click="navigateTo('/statistics')">
              <i class="el-icon-setting"></i>
              数据统计
            </el-menu-item>
            <el-menu-item index="3" @click="navigateTo('/change-password')">
              <i class="el-icon-user"></i>
              修改密码
            </el-menu-item>
            <el-menu-item index="4" @click="navigateTo('/operation-records')">
              <i class="el-icon-document"></i>
              操作记录
            </el-menu-item>
          </el-menu>
        </div>
        <div class="header-right">
          <el-avatar :src="require('@/assets/avatar.png')" size="large"></el-avatar>
          <span class="username">{{ userName }}</span>
          <el-button link @click="logout" class="logout-button">
            退出登录
          </el-button>
        </div>
      </div>
    </el-header>

    <router-view></router-view>
  </el-container>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: '1'
    };
  },
  computed: {
    userName() {
      return localStorage.getItem('member_name');
    },
    showNavbar() {
      return this.$route.name !== 'Login' && this.$route.name !== 'Register';
    }
  },
  watch: {
    '$route'(to, from) {
      this.updateActiveIndex(to.path);
    }
  },
  methods: {
    navigateTo(path) {
      this.$router.push(path);
    },
    updateActiveIndex(path) {
      switch (path) {
        case '/dashboard':
          this.activeIndex = '1';
          break;
        case '/statistics':
          this.activeIndex = '2';
          break;
        case '/change-password':
          this.activeIndex = '3';
          break;
        case '/operation-records':
          this.activeIndex = '4';
          break;
        default:
          this.activeIndex = '1';
          break;
      }
    },
    logout() {
      localStorage.clear();
      sessionStorage.clear();
      window.location.replace('/login'); // 使用replace避免添加到历史记录
    }
  },
  created() {
    this.updateActiveIndex(this.$route.path);
  }
};
</script>

<style scoped>
.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #001529;
  color: white;
  padding: 10px 20px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.header-left {
  flex: 1;
}

.header-right {
  display: flex;
  align-items: center;
}

.header-right .username {
  margin-left: 10px;
  margin-right: 20px;
  color: white;
  font-weight: bold;
  text-shadow: 0 0 8px rgba(255, 255, 255, 0.8);
}

.logout-button {
  color: white;
}
</style>
