<template>
  <div class="auth-background">
    <div class="auth-container">
      <el-card class="auth-card">
        <h2>注册</h2>
        <el-form :model="registerForm" @submit.prevent="register">
          <el-form-item label="用户名">
            <el-input v-model="registerForm.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="密码啊">
            <el-input type="password" v-model="registerForm.password" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="邀请码">
            <el-input v-model="registerForm.invite_code" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="register" class="register-button">注册</el-button>
          </el-form-item>
          <el-link @click="goToLogin" class="login-link">已有账号？去登录</el-link>
        </el-form>
      </el-card>
    </div>
  </div>
</template>

<script>
import API_BASE_URL from '../config';
export default {
  data() {
    return {
      registerForm: {
        name: '',
        password: '',
        invite_code: ''
      }
    };
  },
  methods: {
    async register() {
      try {
        const response = await fetch(`${API_BASE_URL}/register`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(this.registerForm)
        });
        const result = await response.json();
        if (result.success) {
          this.$message.success('注册成功');
          this.$router.push('/');
        } else {
          this.$message.error(result.message);
        }
      } catch (error) {
        console.error(error);
        this.$message.error('注册失败');
      }
    },
    goToLogin() {
      this.$router.push('/');
    }
  }
};
</script>

<style scoped>
.auth-background {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-image: url('https://pic.netbian.com/uploads/allimg/240712/235820-1720799900b518.jpg');
  background-size: cover;
  background-position: center;
}

.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
}

.auth-card {
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  background-color: rgba(255, 255, 255, 0.1);
  text-align: center;
  width: 400px;
  z-index: 1;
}

h2 {
  margin-bottom: 20px;
  color: #333333;
}

.register-button {
  width: 100%;
}

.login-link {
  display: block;
  margin-top: 10px;
}
</style>
