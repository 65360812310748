<template>
  <el-container>
    <el-main>
      <div class="content-spacing">
        <div v-if="loading" class="custom-loading">
          <div class="spinner"></div>
        </div>

        <el-card class="box-card" v-else>
          <div class="card-header">
            <span>总扣除详细</span>
            <el-button @click="fetchLatestData" type="primary" :loading="loading">点我获取最新数据</el-button>
          </div>
          <el-table :data="[calculatedTotalDeductionDetails]" class="table-container" style="width: 100%;" border
            stripe>
            <el-table-column v-for="(date, index) in getDateRange()" :key="index" :label="date" prop="date" width="150">
              <template #default="scope">
                {{ calculatedTotalDeductionDetails.total_deduction[index] }}
              </template>
            </el-table-column>
            <el-table-column label="总数合计" width="180">
              <template #default="scope">
                {{ calculatedTotalDeductionDetails.total_deduction.reduce((sum, item) => sum + item, 0) }}
              </template>
            </el-table-column>
          </el-table>
        </el-card>

        <el-card class="box-card">
          <el-table :data="calculatedStatisticsDataList" class="table-container" style="width: 100%;" border stripe
            table-layout="fixed">
            <el-table-column prop="type" label="归属" width="250"></el-table-column>
            <el-table-column prop="total_original" label="总原始数据" width="250"></el-table-column>
            <el-table-column prop="total_deducted" label="总扣后数据" width="250"></el-table-column>
            <el-table-column prop="deduction_details" label="总扣除详细" width="250"></el-table-column>
            <el-table-column prop="total_deduction" label="总扣除合计" width="180"></el-table-column>
            <el-table-column prop="total_count" label="卡密总数合计" width="180"></el-table-column>
          </el-table>
        </el-card>

        <el-card class="box-card">
          <div class="card-header">
            <el-select v-model="searchField" placeholder="选择搜索字段" class="search-input">
              <el-option label="原卡" value="original_card"></el-option>
              <el-option label="归属" value="type"></el-option>
              <el-option label="系数" value="coefficient"></el-option>
            </el-select>
            <el-input v-model="searchQuery" placeholder="搜索" class="search-input" clearable @clear="fetchDataDetails" />
            <el-button @click="fetchDataDetails" type="primary" :loading="loading">搜索</el-button>
          </div>
          <el-table :data="dataDetailsList" class="table-container" style="width: 100%;" border stripe
            table-layout="fixed">
            <el-table-column label="序号" width="60">
              <template #default="scope">
                {{ scope.$index + 1 }}
              </template>
            </el-table-column>
            <el-table-column prop="original_card" label="原卡" width="260"></el-table-column>
            <el-table-column prop="deduct_start" label="起扣" width="200"></el-table-column>
            <el-table-column prop="coefficient" label="系数" width="60"></el-table-column>
            <el-table-column prop="type" label="归属" width="250"></el-table-column>
            <el-table-column prop="original_data" label="原始数据" width="180"></el-table-column>
            <el-table-column prop="deducted_data" label="扣后数据" width="180"></el-table-column>
            <el-table-column prop="deduction_details" label="扣除详细" width="180"></el-table-column>
            <el-table-column prop="total_deduction" label="扣除合计" width="180"></el-table-column>
          </el-table>

          <div class="pagination-container">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
              :current-page="currentPage" :page-sizes="[5, 10, 50, 100, 500, 9999]" :page-size="pageSize"
              layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>
        </el-card>

      </div>
    </el-main>
  </el-container>
</template>

<script>
import API_BASE_URL from '../config';
export default {
  data() {
    return {
      dataDetailsList: [],
      statisticsDataList: [],
      totalDeductionDetails: { total_deduction: Array(7).fill(0) },
      loading: false,
      searchQuery: '',
      searchField: 'original_card',
      currentPage: 1,
      pageSize: 9999,
      total: 0
    };
  },
  computed: {
    filteredDataDetailsList() {
      return this.dataDetailsList;
    },
    calculatedStatisticsDataList() {
      const filtered = this.dataDetailsList;
      const statistics = {};
      filtered.forEach(item => {
        if (!statistics[item.type]) {
          statistics[item.type] = {
            total_original: Array(7).fill(0),
            total_deducted: Array(7).fill(0),
            deduction_details: Array(7).fill(0),
            total_deduction: 0,
            total_count: 0
          };
        }
        const original_data = JSON.parse(item.original_data);
        const deducted_data = JSON.parse(item.deducted_data);
        const deduction_details = JSON.parse(item.deduction_details);

        original_data.forEach((num, idx) => {
          statistics[item.type].total_original[idx] += num;
        });
        deducted_data.forEach((num, idx) => {
          statistics[item.type].total_deducted[idx] += num;
        });
        deduction_details.forEach((num, idx) => {
          statistics[item.type].deduction_details[idx] += num;
        });
        statistics[item.type].total_deduction += item.total_deduction;
        statistics[item.type].total_count += 1;
      });
      return Object.keys(statistics).map(type => ({
        type,
        ...statistics[type]
      }));
    },
    calculatedTotalDeductionDetails() {
      const filtered = this.dataDetailsList;
      const totalDeductionDetails = Array(7).fill(0);
      filtered.forEach(item => {
        const deduction_details = JSON.parse(item.deduction_details);
        deduction_details.forEach((num, idx) => {
          totalDeductionDetails[idx] += num;
        });
      });

      return { total_deduction: totalDeductionDetails };
    }
  },
  methods: {
    async fetchLatestData() {
      try {
        this.loading = true;
        const member_id = localStorage.getItem('member_id');
        if (!member_id) {
          this.$message.error('会员ID未找到，请重新登录');
          this.$router.push('/login'); // 跳转到登录页面
          return;
        }

        await fetch(`${API_BASE_URL}/statistics`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ member_id })
        });

        this.fetchDataDetails(); // 获取最新的数据
      } catch (error) {
        this.$message.error('获取最新数据失败');
      } finally {
        this.loading = false;
      }
    },
    async fetchDataDetails() {
      try {
        this.loading = true;
        const member_id = localStorage.getItem('member_id');
        const response = await fetch(`${API_BASE_URL}/getDataDetails?page=${this.currentPage}&limit=${this.pageSize}&searchField=${this.searchField}&searchQuery=${this.searchQuery}&member_id=${member_id}`);
        const result = await response.json();
        this.dataDetailsList = result.data;
        this.total = result.total;
      } catch (error) {
        this.$message.error('获取数据失败');
      } finally {
        this.loading = false;
      }
    },
    getDateRange() {
      const dates = [];
      for (let i = 0; i < 7; i++) {
        const date = new Date();
        date.setDate(date.getDate() - i);
        dates.push(`${date.getMonth() + 1}-${date.getDate()}`);
      }
      return dates;
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.fetchDataDetails();
    },
    handleCurrentChange(page) {
      this.currentPage = page;
      this.fetchDataDetails();
    }
  },
  created() {
    this.fetchDataDetails();
  }
};
</script>

<style scoped>
.header {
  display: flex;
  justify-content: flex-start;
  padding: 20px;
}

.content-spacing {
  margin-top: 20px;
}

.box-card {
  margin-bottom: 5px;
}

.table-container {
  width: 100%;
  table-layout: fixed;
}

.card-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.search-input {
  margin-right: 5px;
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.custom-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.spinner {
  width: 50px;
  height: 50px;
  border: 5px solid rgba(0, 0, 0, 0.1);
  border-top-color: #09f;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
</style>
